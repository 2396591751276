import React, { useState/*, useEffect*/ } from 'react';
import { Link } from 'react-router-dom';
//import { Button } from './Button';
import './Navbar.css'
import logo from '../images/TGK Logo.png';

function Navbar() {
    const [click, setClick] = useState(false);
    //const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    /*const showButton = () => {
      if(window.innerWidth <= 960) {
        setButton(false)
      } else {
        setButton(true);
      }
    };

    useEffect(() => {
      showButton();
    }, []);*/
    
    //window.addEventListener('resize'/*, showButton*/);
    
    return (
    <>
      <nav className="Navbar">
        <div className="navbar-container">
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times': 'fas fa-bars'}/>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'>
                <Link to='/values' className='nav-links' onClick={closeMobileMenu}>
                Values
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/my-story' className='nav-links' onClick={closeMobileMenu}>
                My Story
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/reviews' className='nav-links' onClick={closeMobileMenu}>
                Reviews
                </Link>
              </li>
              <li className='nav-item'>
                <Link to="/" className="navbar-logo nav-links" onClick={closeMobileMenu}>
                <img className='logo' src={logo} alt='TGK Concepts LLC'/> 
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/menu' className='nav-links' onClick={closeMobileMenu}>
                Menu
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/shop' className='nav-links' onClick={closeMobileMenu}>
                Shop
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                Services
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/contact' className='nav-link-last nav-last' onClick={closeMobileMenu}>
                Contact
                </Link>
              </li>

            </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar;