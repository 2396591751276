import React from "react";
import '../../App.css';
import Footer from "../Footer";


export default function Shop() {
    const style1={'text-align':'center'};
    const style2={'padding':'2em'};
    return (
    <div>
    <h1 className='menu mask-text-2 value-title'>Shop</h1>
        <p style={{...style1,...style2}}>Coming Soon! Test</p>
    <Footer/>
    </div>
    )
}
