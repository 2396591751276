import React from 'react';
import'../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <video autoPlay loop muted webkit-playsinline playsinline src='/videos/montage.mp4' />

        <h1 className='mask-text'> LET ME COOK</h1>
        <br />
        <div className="hero-btns">
            <Button 
                className="btns" 
                buttonStyle='btn--primary' 
                buttonSize='btn--large'
            >
                GET QUOTE
            </Button>
            <Button 
                className="btns" 
                buttonStyle='btn--outline' 
                buttonSize='btn--large'
            >
                LIVE MENU <i className='far fa-play-circle' />
            </Button>
        </div>
    </div>
  )
}

export default HeroSection;