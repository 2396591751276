import React from 'react';
import {Button} from './Button';
import './Footer.css'
import { Link } from 'react-router-dom';
import logo from '../images/TGK Logo.png';

function Footer() {
  return (
    <div className='footer-container'>
      <div className="footer-sections">    
        <div className="footer-top-section">
          <section className="social-media">
            <div className="social-media wrap">
              <div className="footer-logo">
                <Link to='/'onClick={() => {window.scroll(0, 0)}} className="social-logo">
                  <img className='logo' src={logo} alt='TGK Concepts LLC'/> 
                </Link>
              </div>
              <div className="social-icons">
                <Link className="social-icon-link facebook"
                  to='https://www.facebook.com/cookingwithtgk'
                  target="blank"
                  aria-label='Facebook'
                  >
                    <i className="fab fa-facebook-f"></i>
                </Link>
                <Link className="social-icon-link instagram"
                  to='https://www.instagram.com/cookingwithtgk/'
                  target="blank"
                  aria-label='Instagram'
                  >
                    <i className="fab fa-instagram"></i>
                </Link>
              </div>
              </div>
            </section>
            <section className="footer-subscription">
            <p className="footer-subscription-heading">
              Join the TGK Concepts newsletter to receive my experiences
            </p>
            <p className="footer-subscription-text">
              You can unsubscribe at any time.
            </p>
            <div className="input-areas">
              <form>
                <input type="email" name="email" placeholder='Your Email' className="footer-input" />
                  <Button buttonStyle='btn--outline'>Subscribe</Button>
              </form>
            </div>
          </section>
          <section className="footer-link-wrapper">
            <div className="footer-links">
                <h2>Navigation</h2>
                <Link to='/values' onClick={() => {window.scroll(0, 0)}}>Values</Link>
                <Link to='/my-story' onClick={() => {window.scroll(0, 0)}}>My Story</Link>
                <Link to='/menu' onClick={() => {window.scroll(0, 0)}}>Menu</Link>
                <Link to='/shop' onClick={() => {window.scroll(0, 0)}}>Shop</Link>
                <Link to='/reviews' onClick={() => {window.scroll(0, 0)}}>Leave Me a Review</Link>
                <Link to='/contact' onClick={() => {window.scroll(0, 0)}}>Contact</Link>
              </div>
          </section>
          
          </div>
          <div className="footer-bottom-section">
            <div className="website-rights-container">
              <small className="website-rights">Oregon Food Handlers #2021o2218748</small>
              <small className="website-rights">TGK Concepts LLC © 2023 | All Rights Reserved | Website created with React by Brice Amarasinghe</small>
            </div>
          </div>
        </div>    
    </div>
  )
}

export default Footer