import React from "react";
import '../../App.css';
import Footer from "../Footer";
import Timeline from "../Timeline";
import headshot from "../../images/TGK_Headshot4.jpg";
import signature from '../../images/TGKSignature.png';

export default function MyStory() {
    return (
    <div className='my-story'>
        <h1 className="my-story-title">My Story</h1>
            <div className="my-story-paragraph-container">
            <div className="image-border">
                <img className="tgk-headshot" src={headshot} alt="TGK Headshot"/>
            </div>
                <p>My name is Todd Gregory King, I am 28 years old and my love for food started when I was in elementary school. My mom would teach me how to prepare our favorite meals like golden mashed potatoes, bacon wrapped filet mignon and taco soup. While cooking together, she would have me add in a few tablespoons of this special spice labeled “love” from a can that sat next to our stovetop. This ingredient has been key in my recipes ever since… A few ounces from an empty spice can filled with some “love” really makes food taste better. From then on, I always appreciated food, especially because of the love and time it takes to make the best meals.
                <br/>
                <br/>
                Most of my favorite memories growing up were centered around great food and laughter with friends and family around a table. In 2016, my Senior Year at Oregon State University, I had the opportunity to cook in a competition for the University’s Housing & Dining Program. The week leading up to the event, I practiced cooking for friends daily in a commercial kitchen at my fraternity house and fell in love with the art of food. Sharing my creations with friends, family and people I often didn’t know quickly became one of the most addicting experiences for me. So I just kept on cooking so I could sit at a table and tell stories with my loved ones.
                <br/>
                <br/>
                That same year, my passion for cooking solidified and “Cooking with TGK” was born! I began to focus on learning about local ingredients, ingredient cultivation, proper preparation and service of food. As I’ve continued to learn and practice, amazing opportunities to share my food and passion with others at their homes or events has led me into private cooking as a part time career.
                <br/>
                <br/>
                To my friends, family, followers - Thank you for being there with me every step of the way and for every ounce of support.
                <br/>
                <br/>
                I can always add more seats to my table, I hope we can get together for a meal sometime.
                <br/>
                <br/>
                Much Love,
                <br/>
                Todd Gregory King
                <br/>
                TGK
                </p>
                <img className="tgk-signature" src={signature} alt="TGK Signature"/>
            </div>
            <div className="experience">
                <h1 className="my-story-experience">My Experience</h1>
                <Timeline/>
            </div>
    <Footer/>
    </div>
    )
}