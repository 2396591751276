import React from "react";
import '../../App.css';
import Footer from "../Footer";
import { InstagramEmbed } from "react-social-media-embed";

export default function Menu() {
    return (
    <div>
    <h1 className='menu mask-text-2 value-title'>TGK Menu</h1>
    <div className="instagram-container">
        <InstagramEmbed url="https://www.instagram.com/p/CxUAoZnvgV7/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/CkXD5xcJm8d/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/Cg4mkqirQKl/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/CgVmgQwL63z/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/Cf75YFXrUDU/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/CfsFos_Pbv-/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/Cd9mHKZrUq8/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/CdgwX1Ch5Yt/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/Cde010Vrdps/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/Cc4LEUBrGS_/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/CY-rBQcrmyx/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/CYALV1cvmcZ/" width= {328} height={380}/>
        <InstagramEmbed url="https://www.instagram.com/p/CXAQrSPFHYc/" width= {328} height={380}/>
    </div>
    <Footer/>
    </div>
    )
}