import React from 'react';
import Navbar from './components/Navbar.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home.js';
import Values from './components/pages/Values.js';
import MyStory from './components/pages/MyStory.js';
import Menu from './components/pages/Menu.js';
import Shop from './components/pages/Shop.js';
import Reviews from './components/pages/Reviews.js';
import Services from './components/pages/Services.js';
import Contact from './components/pages/Contact.js'

function App() {
  return (
    <>
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' element=
          {<Home/>} />
          <Route path='/values' element=
          {<Values/>} />
          <Route path='/my-story' element=
          {<MyStory/>} />
          <Route path='/menu' element=
          {<Menu/>} />
          <Route path='/shop' element=
          {<Shop/>} />
          <Route path='/reviews' element=
          {<Reviews/>} />
          <Route path='/services' element=
          {<Services/>} />
          <Route path='/contact' element=
          {<Contact/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
