import React from 'react'
import Footer from '../Footer'
import '../../App.css'


function Contact() {
  return (
    <div>
        <h1 className='contact mask-text-2 value-title'>Contact</h1>
        <div className='columns-3'>
          <div className='empty-section-1'> </div>
          <div className='form-section'>
              <form className='contact-form'>
                <label>First Name:
                  <input type='text' placeholder='Enter First Name' />
                </label>
                <label>Last Name:
                  <input type='text' placeholder='Enter Last Name' />
                </label>
                <label>Phone Number:
                  <input type='tel' placeholder='Enter Phone Number' maxLength="10"/>
                </label>
                <label>Email:
                  <input type='email' placeholder='Enter Email' />
                </label>
                <label>Address:
                  <input type='text' placeholder='Enter Street Address' />
                </label>
                <label>Suite/Floor:
                  <input type='text' placeholder='Enter Suite and Floor' />
                </label>
                <label>City:
                  <input type='text' placeholder='Enter City' />
                </label>
                <label>State:
                  <select id='states'>
                    <option selected="selected" disabled value='Choose State'>Choose State</option>
                    <option value='AL'>Alabama</option>
                    <option value='AK'>Alaska</option>
                    <option value='AZ'>Arizona</option>
                    <option value='AR'>Arkansas</option>
                    <option value='AS'>American Samoa</option>
                    <option value='CA'>California</option>
                    <option value='CO'>Colorado</option>
                    <option value='CT'>Connecticut</option>
                    <option value='DE'>Delaware</option>
                    <option value='DC'>District of Columbia</option>
                    <option value='FL'>Florida</option>
                    <option value='GA'>Georgia</option>
                    <option value='GU'>Guam</option>
                    <option value='HI'>Hawaii</option>
                    <option value='ID'>Idaho</option>
                    <option value='IL'>Illinois</option>
                    <option value='IN'>Indiana</option>
                    <option value='IA'>Iowa</option>
                    <option value='KS'>Kansas</option>
                    <option value='KY'>Kentucky</option>
                    <option value='LA'>Louisiana</option>
                    <option value='ME'>Maine</option>
                    <option value='MD'>Maryland</option>
                    <option value='MA'>Massachusetts</option>
                    <option value='MI'>Michigan</option>
                    <option value='MN'>Minnesota</option>
                    <option value='MS'>Mississippi</option>
                    <option value='MO'>Missouri</option>
                    <option value='MT'>Montana</option>
                    <option value='NE'>Nebraska</option>
                    <option value='NV'>Nevada</option>
                    <option value='NH'>New Hampshire</option>
                    <option value='NJ'>New Jersey</option>
                    <option value='NM'>New Mexico</option>
                    <option value='NY'>New York</option>
                    <option value='NC'>North Carolina</option>
                    <option value='ND'>North Dakota</option>
                    <option value='MP'>Northern Mariana Islands</option>
                    <option value='OH'>Ohio</option>
                    <option value='OK'>Oklahoma</option>
                    <option value='OR'>Oregon</option>
                    <option value='PA'>Pennsylvania</option>
                    <option value='PR'>Puerto Rico</option>
                    <option value='RI'>Rhode Island</option>
                    <option value='SC'>South Carolina</option>
                    <option value='SD'>South Dakota</option>
                    <option value='TN'>Tennessee</option>
                    <option value='TX'>Texas</option>
                    <option value='UT'>Utah</option>
                    <option value='VT'>Vermont</option>
                    <option value='VA'>Virginia</option>
                    <option value='VI'>Virgin Islands</option>
                    <option value='WA'>Washington</option>
                    <option value='WV'>West Virginia</option>
                    <option value='WI'>Wisconsin</option>
                    <option value='WY'>Wyoming</option>
                  </select>
                </label>
                <label> Zip Code:
                  <input type='tel' placeholder='Enter Zip Code' maxLength="5"/>
                </label>
              </form>
          </div>
          <div className='empty-section-2'> </div>
        </div>
        
    <Footer/>
    </div>
  )
}

export default Contact