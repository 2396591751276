import React from "react";
import '../../App.css';
import passion from '../../icons/passion_7172930.png';
import love from '../../icons/heart_1538598.png';
import focus from '../../icons/target_916673.png';
import precision from '../../icons/compass_1547543.png';
import candid from '../../icons/knowledge_2883662.png';
import Footer from "../Footer";
import passionVineyard from "../../images/passionPic.jpg"
import lovePic from "../../images/lovePic.jpg"
import focusPic from "../../images/focusPic.jpg"
import precisionPic from "../../images/precisionPic.jpg"
import candidPic from "../../images/candidPic.jpg"

export default function Values() {
    return (
        <div className='values'>
            <h1 className='value-title mask-text-2'>Values</h1> 
            <div className='value-pillars'>
                <div className='value-card'>
                    <div className="left-side-card-values">
                        <img className='value-icons' src={passion} alt='Passion'/> 
                        <h3 className="value-titles">Passion</h3>
                        <p>for local ingredients and supporting our farmers, cultivators, foragers</p>
                    </div>
                    <div className="right-side-card-values">
                        <img className='value-pics' src={passionVineyard} alt='Passion'/> 
                    </div>
                </div>
                <div className='value-card value-love'>
                    <div className="right-side-card-values">
                        <img className='value-pics' src={lovePic} alt='Love'/> 
                    </div> 
                    <div className="left-side-card-values">
                        <img className='value-icons' src={love} alt='Love'/> 
                        <h3 className="value-titles">Love</h3>
                    <p>for every person, animal, great meal and irreplaceable experience with friends and family</p>
                    </div>
                </div>
                <div className='value-card'>
                    <div className="left-side-card-values">
                        <img className='value-icons' src={focus} alt='Focus'/> 
                        <h3 className="value-titles">Focus</h3> 
                        <p>on the approachability of eating and preparing rare and exotic ingredients</p>
                    </div>
                    <div className="right-side-card-values">
                        <img className='value-pics' src={focusPic} alt='Focus'/> 
                    </div> 
                </div>
                <div className='value-card value-precision'>
                    <div className="right-side-card-values">
                        <img className='value-pics' src={precisionPic} alt='Precision'/> 
                    </div> 
                    <div className="left-side-card-values">
                        <img className='value-icons' src={precision} alt='Precision'/> 
                        <h3 className="value-titles">Precision</h3> 
                        <p>with details and execution of proper technique, as well as health & safety measures</p>
                    </div>
                </div>
                <div className='value-card'>
                    <div className="left-side-card-values">
                        <img className='value-icons' src={candid} alt='Candid'/> 
                        <h3 className="value-titles">Candid</h3> 
                        <p>in teaching and learning, feedback from clients and collaborators is encouraged and welcomed</p>
                    </div>
                    <div className="right-side-card-values">
                        <img className='value-pics' src={candidPic} alt='Candid'/> 
                    </div> 
                </div>
            </div>
            <Footer/>
        </div>
    )
}