import React from "react";
import '../../App.css';
import chef from '../../icons/chef_450166.png';
import plane from '../../icons/plane_870092.png';
import pan from '../../icons/pan_5713177.png';
import dish from '../../icons/dish_882781.png';
import Footer from "../Footer";
import homeChef from '../../images/homeChefPic.jpg';
import cookingLessons from '../../images/cookingLessonsPic.jpeg';
import catering from '../../images/cateringPic.JPG';
import vacation from '../../images/vacationPic.jpg';

export default function Services() {
    return (
        <div >
            <h1 className="services mask-text-2 value-title">Services</h1>
            <div className='value-pillars'>
                <div className='value-card'>
                    <div className="left-side-card-services">
                        <img className='value-icons' src={chef} alt='In Home Chef/Private Meals'/> 
                        <h3 className="value-titles">In Home Chef/Private Meals</h3>
                        <p>I am available for Pacific Northwest residents who are looking for private meals cooked in the comfort of their home. This includes breakfasts, lunches, dinners and small plates/appetizers. <br/> (40 person max)
                        </p>
                    </div>
                    <div className="right-side-card-services">
                        <img className='value-pics' src={homeChef} alt='Home Chef'/> 
                    </div>
                </div>
                <div className='value-card service-vacation'>
                    <div className="right-side-card-services">
                        <img className='value-pics' src={vacation} alt='Vacation'/> 
                    </div>
                    <div className="left-side-card-services">
                        <img className='value-icons' src={plane} alt='Vacation/AirBnb Chef'/> 
                        <h3 className="value-titles">Vacation/AirBnb Chef</h3> 
                        <p>Throwing a getaway or party for a special occasion? Hire me to shop for ingredients, cook the food and clean the kitchen for the entirety of your trip or just one day. <br/> (15 person max)</p>
                    </div>
                </div>
                <div className='value-card'>
                    <div className="left-side-card-services">
                        <img className='value-icons' src={dish} alt='Catering/Private Events'/> 
                        <h3 className="value-titles">Catering/Private Events</h3> 
                        <p>Hire me to cook for your large scale private or corporate events! My style of catering is focused on giving every event attendee flavorful, well presented, top-tier local ingredients. <br/> (125 person max)</p>
                    </div>
                    <div className="right-side-card-services">
                        <img className='value-pics' src={catering} alt='Catering'/> 
                    </div>
                </div>
                <div className='value-card service-lessons'>
                    <div className="right-side-card-services">
                    <img className='value-pics' src={cookingLessons} alt='Cooking Lessons'/> 
                    </div>
                    <div className="left-side-card-services">
                        <img className='value-icons' src={pan} alt='Private Cooking Lessons'/> 
                        <h3 className="value-titles">Private Cooking Lessons</h3> 
                        <p>I don’t claim to know the most out of every chef in the world but I can help you learn classic and explorative techniques, flavor pairings and food preparations. With very little time we can cover quick/easy ways to up your cooking game cooking at home or just cook a recipe you’d want to learn. <br/> (10 person max)
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}