import React from "react";
import '../../App.css';
import Footer from "../Footer";

export default function Reviews() {
    return (
    <div>
        <h1 className='reviews mask-text-2 value-title'>Reviews</h1>
        <p>add reviews here. maybe from google instead of typing individually?</p>
        <Footer/>
    </div>
    )
}