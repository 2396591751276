import React from 'react'
import './Timeline.css'
import { Chrono } from 'react-chrono'

const Timeline = () => {
    const items = [{
      title: "2016-Current",
      cardTitle: "TGK Concepts LLC",
      url: "/",
      cardSubtitle:"Portland, OR",
      cardDetailedText: "TGK Concepts LLC, founded by Todd Gregory King, is a culinary endeavor rooted in a deep love for food and a passion for creating memorable dining experiences. TGK Concepts LLC offers a unique blend of flavors, classic techniques, and a heartfelt commitment to bringing people together around the table.",
      media: {
        type: "IMAGE",
        source: {
          url: "https://drive.google.com/uc?id=1pFijwRBhWJ6cK2A8UyaYQmJhaoxsfZF7"
        }
      }
    }, {
        title: "2016-2017",
        cardTitle: "Olive & Ivy",
        url: "https://www.oliveandivyrestaurant.com/",
        cardSubtitle:"Scottsdale, AZ",
        cardDetailedText: "Olive and Ivy, a Mediterranean eatery, is a culinary gem crafted by Sam Fox, a hospitality industry veteran known for his early start in the restaurant world and innovative dining concepts.",
        media: {
          type: "IMAGE",
          source: {
            url: "https://drive.google.com/uc?id=15XsEGCREu7XNBMD9anzjs6M2UZBb9QgW"
          }
        }
      },
      {
        title: "2016",
        cardTitle: "Sugoi Sushi",
        url: "https://www.facebook.com/ilovesugoisushi/",
        cardSubtitle:"Corvallis, OR",
        cardDetailedText: "Sugoi Sushi, a beloved local college town staple, is renowned for its exceptional service and an enticing menu featuring fresh fish, rice dishes, and delectable yakisoba.",
        media: {
          type: "IMAGE",
          source: {
            url: "https://drive.google.com/uc?id=1O2Xt1RyFdannN1Gs8dLR7z4U_Lfzxm_5"
          }
        }
      },
    {
        title: "2012-2016",
        cardTitle: "Oregon State University",
        url: "/",
        cardSubtitle:"Corvallis, OR",
        cardDetailedText: "During Todd's time at Oregon State University, he cultivated his passion for cooking while actively participating in his fraternity and making strides as a dedicated student of fair ability.",
        media: {
            type: "IMAGE",
            source: {
                url: "https://drive.google.com/uc?id=1UVK8XFy6ubCgUtpUm3cO6CvGovXSeYXV"
            }
        }
    }
    ];

    return (
    <div className="timeline-container"> 
      <div style={{ width: "800px", height: "800px" }}>
          <Chrono items={items} 
          slideShow
          slideItemDuration={3000}
          slideShowType="reveal"
          lineWidth = {0}
          theme={{
              primary: 'green',
              secondary: 'white',
              cardBgColor: 'white',
              titleColor: 'black',
              titleColorActive: 'green',
          }}
          fontSizes={{
              cardSubtitle: '0.85rem',
              cardText: '1rem',
              cardTitle: '24px',
              title: '1rem',
          }}/>
        </div>
      </div> 
    )
  }

export default Timeline